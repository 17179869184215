@keyframes blink {
	0% {
	  opacity:0;
	}
  50%{
    opacity: .5;
  }
	100% {
		opacity:1;
	}
  }
  #loader{
      width: 100%;
      height: 100%;
      position: fixed;
      top:0;
      z-index: 999;
      background: #fff;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      
      visibility: hidden;
      opacity: 1;
      transition: 0.5s ease;
      
      &.loader{
          transition:none !important;
          visibility:visible;
          .loader__img{
            width: 250px;
            height: 150px;
            background-image: url('../images/logo-light.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            animation: blink 0.7s ease-in infinite;
          }
          img{
            position: relative;
            z-index: 10;
            max-width: 250px;
          }

      }
  }

body.dark-mode{
  
  #loader{
    background: #000;
    &.loader{
      .loader__img{
        background-image: url('../images/logo-dark.png');
      }
    }
  }
}